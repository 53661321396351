import React from "react"
import PropTypes from "prop-types"
import TestimonialCard from "./TestMonial"
import { useCustomDevice } from "components/hooks/useCustomDevice"

function Testimonials({ data }) {
  const web = (
    <div className="container">
      <div className="px-2">
        <div className="customerReview style-2">
          {data?.title && (
            <h2
              className="section-head mb-2"
              style={{ textAlign: data?.textAlign }}
            >
              {data?.title}
            </h2>
          )}
          {data?.description && (
            <p className="section-description mb-4">{data?.description}</p>
          )}
          <TestimonialCard data={data} />
        </div>
      </div>
    </div>
  )
  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default Testimonials

Testimonials.propTypes = {
  data: PropTypes.any,
}
