import React, { Suspense, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useDispatch, useSelector } from "react-redux"
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom"

// Import Routes all
import { publicRoutes, AuthProtectedRoute } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import NonAuthLayout from "./components/NonAuthLayout"
import Preloader from "components/elements/Preloader"
import ReactGA from "react-ga"

import "react-perfect-scrollbar/dist/css/styles.css"
import "react-responsive-modal/styles.css"
import "swiper/swiper-bundle.min.css"
import "swiper/swiper.min.css"
import "./assets/css/main.css"
import "./assets/css/custom.css"
import "./assets/sass/custom.scss"

import { Notification } from "components/Common/Notification"
import LoginPopup from "pages/user/LoginPopup"
import LocationPopUp from "components/elements/IntroPopup"

import {
  getNearestStore,
  getNearestStoreNoUser,
  getUserLocation,
  getUserPlaces,
  getFavouriteItems,
  getPagesHome,
  getUser,
  setLanguageAction,
  showLocationPopupAction,
} from "store/actions"

import CartBottomNotification from "components/elements/cartBottomNotification"
import { getSiteSettings, getDefaultStore } from "store/settings/actions"
import UesWindowScrollTop from "components/hooks/uesWindowScrollTop"
import MobileNav from "components/layout/MobileNav"
import storage from "util/localStorage"
import LandingPage from "pages/LandingPages"
import BannerLoader from "components/Common/loaders/BannerLoader"
import AreaListPopup from "components/elements/AreaListPopup"
import { TOKEN_PREFIX } from "helpers/api_methods"

const App = props => {
  const token = localStorage.getItem(TOKEN_PREFIX)
  const dispatch = useDispatch()
  const location = useLocation()

  const [loading, setLoading] = useState(false)
  const TRACKING_ID = "G-15P77D02FM"
  ReactGA.initialize(TRACKING_ID)

  const {
    isLoginPopup,
    cartNoti,
    favorites,
    homePage,
    siteSettings,
    isLocationPopup,
    location: userLocation,
    currentStore,
    defaultStore,
  } = useSelector(state => ({
    isLoginPopup: state.Layout.isLoginPopup,
    isLocationPopup: state.Layout.isLocationPopup,
    location: state.NearbyStore.location,
    cartNoti: state.Layout.cartNoti,
    favorites: state.Cart.favorites,
    homePage: state?.LayoutPages.homePage,
    siteSettings: state?.SiteSettings.siteSettings,
    currentStore: state.NearbyStore.store,
    defaultStore: state.SiteSettings.defaultStore,
  }))

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2500)
  }, [])

  useEffect(() => {
    const pre_location = localStorage.getItem("pre_location")
    const lat = parseFloat(pre_location?.split(",")[0])
    const lng = parseFloat(pre_location?.split(",")[1])

    if (pre_location) {
      dispatch(getUserLocation([lat, lng]))
    } else {
      setTimeout(() => {
        dispatch(showLocationPopupAction())
      }, 2000)
      // navigator.geolocation.getCurrentPosition(
      //   function (position) {
      //     dispatch(
      //       getUserLocation([
      //         position.coords.latitude,
      //         position.coords.longitude,
      //       ])
      //     )
      //   },
      //   function () {
      //     dispatch(getDefaultStore())
      //   }
      // )
    }
  }, [dispatch])

  useEffect(() => {
    if (token) {
      dispatch(getUser())
    }
  }, [token])

  useEffect(() => {
    dispatch(setLanguageAction("en"))
  }, [])

  useEffect(() => {
    dispatch(getSiteSettings())
  }, [dispatch])

  useEffect(() => {
    if (!token) {
      dispatch(getDefaultStore())
    }
  }, [token])

  useEffect(() => {
    if (userLocation?.length === 2) {
      if (token) {
        dispatch(getNearestStore({ coordinates: userLocation }))
      } else {
        dispatch(getNearestStoreNoUser({ coordinates: userLocation }))
      }

      dispatch(getUserPlaces(userLocation[0], userLocation[1]))
    }
  }, [userLocation, dispatch, token])

  useEffect(() => {
    if (isLoginPopup === true) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [isLoginPopup])

  useEffect(() => {
    dispatch(getFavouriteItems(1, 20))
  }, [dispatch])

  useEffect(() => {
    const favIds = favorites?.favorites?.map(fav => fav.product?._id)
    if (favIds?.length >= 1) {
      storage.set("user_favourite", favIds)
    }
  })

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search)
  }, [])

  useEffect(() => {
    if (siteSettings?.setup?.isCustomHomePage) {
      dispatch(getPagesHome(currentStoreId, "home"))
    }
  }, [siteSettings?.setup, currentStoreId])

  const theme = siteSettings?.setup?.theme

  const handlePublicRoutes = () => {
    if (
      siteSettings?.setup?.isCustomHomePage &&
      homePage?.sections &&
      homePage?.sections[0]?._id
    ) {
      const modifiedRoute = publicRoutes?.map(route =>
        route?.path === "/" ? { ...route, component: LandingPage } : route
      )
      return modifiedRoute
    } else {
      return publicRoutes
    }
  }

  return (
    <React.Fragment>
      <style>
        {`
       :root {
        --primary-colors : ${
          theme?.primaryColor ? theme?.primaryColor : "#22b3be"
        };
        --secondary-color: ${
          theme?.secondaryColor ? theme?.secondaryColor : "#ed475f"
        };
      }`}
      </style>

      <Notification />
      {isLoginPopup && <LoginPopup />}
      {isLocationPopup && <LocationPopUp />}

      {loading ? (
        <Preloader />
      ) : (
        <Suspense
          fallback={
            <div>
              <BannerLoader />
              <BannerLoader />
              <BannerLoader />
            </div>
          }
        >
          <Router>
            <MobileNav />
            <UesWindowScrollTop />
            {cartNoti && <CartBottomNotification />}
            <AreaListPopup />

            <Switch>
              {handlePublicRoutes()?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                  exact
                />
              ))}
              {AuthProtectedRoute?.map((route, idx) => (
                <Authmiddleware
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              ))}
              <Route render={() => <Redirect to={{ pathname: "/404" }} />} />,
            </Switch>
          </Router>
        </Suspense>
      )}
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
