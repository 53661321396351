import React, { Fragment, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

import iconCart from "assets/imgs/theme/icons/icon-cart.svg"
import iconUser from "assets/imgs/theme/icons/icon-user.svg"
import Search from "components/ecommerce/search/Search"
import {
  getCategories,
  getCollections,
  getFavouriteItems,
  getLocalCart,
  getQuicklist,
  getUserCart,
  logoutUser,
  showLocationPopupAction,
  showLoginPopupAction,
} from "store/actions"
import { map } from "lodash"
import CartItemList from "components/elements/cartItemComp2"
import usePrice from "components/hooks/usePrice"
import EmptyCartSvg from "components/Common/emptyCart"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { useWindowSize } from "react-use"
import { MainLogo } from "assets/imgs/logos"
import { useLocalCartPrices } from "components/hooks/useCartAction"
import useCloudinary from "components/hooks/useCloudinaryImage"
import LazyLoad from "react-lazyload"
import { TOKEN_PREFIX } from "helpers/api_methods"
import { MdLocationOn } from "react-icons/md"

export const pathsThatNotShowInCheckout = [
  "/cart",
  "/checkout",
  "/checkout/login",
]

const Header = ({ toggleClick }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const cateRef = useRef(null)
  const location = useLocation()
  const { width } = useWindowSize()

  const token = localStorage.getItem(TOKEN_PREFIX)

  const {
    userPlace,
    isDefaultStore,
    currentStore,
    defaultStore,
    cartItems,
    userCart,
    company,
    specialMessages,
    favorites,
    collections,
    localCart,
    user,
    allCategories,
  } = useSelector(state => ({
    userPlace: state.NearbyStore.userPlace,
    isDefaultStore: state.NearbyStore.store.isDefault,
    currentStore: state.NearbyStore.store,
    defaultStore: state.SiteSettings.defaultStore,
    user: state.Login.user,
    cartItems: state.Cart.cartItems,
    localCart: state.Cart.localCart,
    userCart: state.Cart.userCart,
    company: state?.SiteSettings.siteSettings?.company,
    specialMessages: state.SiteSettings.siteSettings.specialMessages,
    favorites: state.Cart.favorites,
    collections: state.LayoutGroup.collections,
    allCategories: state.Classification.categories,
  }))

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  const [isToggled, setToggled] = useState(false)
  const [scroll, setScroll] = useState(false)

  const [scrollY, setScrollY] = useState()

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(cateRef, setOutSide)

  useEffect(() => {
    const onScroll = e => {
      setScrollY(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    if (scrollY >= 100 && width > 480) {
      setScroll(true)
    } else {
      setScroll(false)
    }

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollY])

  const handleToggle = () => setToggled(!isToggled)

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  const handleLocationPopup = () => dispatch(showLocationPopupAction())

  const handleLogout = () => {
    dispatch(logoutUser(history))
  }

  useEffect(() => {
    dispatch(getUserCart())
    dispatch(getCategories("", "all"))
    dispatch(getFavouriteItems(1, 10))
    dispatch(getQuicklist(1, 10))
  }, [dispatch])

  useEffect(() => {
    if (currentStoreId) {
      dispatch(getCollections(currentStoreId))
    }
  }, [dispatch, currentStoreId])

  const { currencyCode } = usePrice()

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginFun = path => {
    if (path) {
      history.push(path)
    } else {
      handleLoginPopup()
    }
  }

  useEffect(() => {
    if (outSide === true) {
      setToggled(false)
      setOutSide(false)
    }
  }, [outSide])

  const handleCartItem = () => {
    if (cartItems?.length >= 1) {
      return cartItems
    } else if (localCart?.length >= 1) {
      return localCart?.filter(i => i?.quantity !== 0)
    } else {
      return []
    }
  }

  useEffect(() => {
    if (!token) {
      dispatch(getLocalCart())
    }
  }, [])

  const { itemTotal } = useLocalCartPrices()

  const productPaths = ["/products", "/categroies", "/collections"]
  const topMessageNotShow = ["/checkout", "/order-success", "/checkout/login"]

  const AccountDropDown = (
    <ul>
      <li>
        <Link to="/user-account">
          <span>
            <i className="fi fi-rs-user mr-10"></i>
            My Account
          </span>
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: "/user-account",
            state: { index: 2 },
          }}
        >
          <span>
            <i className="fi fi-rs-location-alt mr-10"></i>
            Order Tracking
          </span>
        </Link>
      </li>
      <li>
        <Link
          to={{
            pathname: "/user-account",
            state: { index: 4 },
          }}
        >
          <span>
            <i className="fi fi-rs-label mr-10"></i>
            My Address
          </span>
        </Link>
      </li>
      <li>
        <Link to="#!" onClick={handleLogout}>
          <span>
            <i className="fi fi-rs-sign-out mr-10"></i>
            Sign out
          </span>
        </Link>
      </li>
    </ul>
  )

  const CartIconAndDropDown = (
    <div className="cart-dropdown-wrap cart-dropdown-hm2">
      {handleCartItem()?.length >= 1 ? (
        <>
          <ul>
            {map(handleCartItem(), (item, key) => (
              <Fragment key={key}>
                <CartItemList item={item} />
              </Fragment>
            ))}
          </ul>

          <div className="shopping-cart-footer">
            <div className="shopping-cart-total">
              {userCart?.shippingPrice ? (
                <p className="d-flex justify-content-between">
                  Shipping
                  <span className="">
                    {currencyCode} {userCart?.shippingPrice ?? 0}
                  </span>
                </p>
              ) : (
                ""
              )}
              {userCart?.couponDiscount ? (
                <p className="d-flex justify-content-between">
                  Coupon Discount
                  <span className="">
                    - {currencyCode} {userCart?.couponDiscount}
                  </span>
                </p>
              ) : (
                ""
              )}
              {userCart?.coupon ? (
                <p className="d-flex justify-content-between">
                  Coupon
                  <span className="">{userCart?.coupon}</span>
                </p>
              ) : (
                ""
              )}
              <h4>
                Total
                <span className="text-black">
                  {currencyCode}{" "}
                  {userCart?.totalPrice ? userCart?.totalPrice : itemTotal}
                </span>
              </h4>
            </div>
            <div className="shopping-cart-button">
              <Link
                onClick={() => handleLoginFun("/cart")}
                to={"#!"}
                className="bg-green"
                style={{ border: "none" }}
              >
                View cart
              </Link>
            </div>
          </div>
        </>
      ) : (
        <EmptyCartSvg style={{ height: "200px" }} />
      )}
    </div>
  )

  const selectCategory = (e, catName) => {
    const removeSpace = catName?.replace(/ /g, "-")
    e.preventDefault()

    if (catName === "all") {
      history.push({
        pathname: "/products",
      })
    } else {
      history.push({
        pathname: "/products",
        query: {
          ...location.query,
          cat: removeSpace,
          isProduct: false,
        },
      })
    }
  }

  return (
    <>
      <div className="brand-color">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

      <header className="header-area header-style-1 header-height-2">
        {!topMessageNotShow?.includes(location.pathname) && (
          <>
            {language?.productOffer && (
              <div className="mobile-promotion">
                {language?.productOffer && (
                  <span>{language?.productOffer}</span>
                )}
              </div>
            )}
          </>
        )}

        {/* // full header top bar */}
        <div className="header-middle header-middle-ptb-1 header-web">
          <div className="container">
            <div className="header-wrap">
              <div className="header-action-right  width-fixed justify-content-start">
                <div
                  className={`header-action-2 me-${token ? 4 : 2} w-100`}
                  style={{ maxWidth: 75 }}
                >
                  <div className="header-action-icon-2">
                    {token ? (
                      <>
                        <Link
                          to="/user-account"
                          className="pe-1 d-flex align-items-center"
                        >
                          <span>
                            <img
                              title="chocochi-user"
                              loading="eager"
                              className="svgInject me-1"
                              alt="chocochi-user"
                              src={iconUser}
                              style={{
                                width: "15px",
                              }}
                            />
                          </span>

                          <span>
                            {user?.name ? (
                              <span
                                className="text-white"
                                style={{ textTransform: "capitalize" }}
                              >
                                {user?.name?.split(" ")[0]}
                              </span>
                            ) : (
                              <span className="lable text-white ml-0">
                                Account
                              </span>
                            )}
                          </span>
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link
                          to="#!"
                          className="me-1"
                          onClick={handleLoginPopup}
                        >
                          <span>
                            <img
                              className="svgInject"
                              alt="chocochi-user"
                              src={iconUser}
                              title="chocochi-user"
                              loading="eager"
                            />
                          </span>
                        </Link>
                        <Link to="#!" onClick={handleLoginPopup}>
                          <span>
                            <span className="lable  text-white ml-0">
                              Login
                            </span>
                          </span>
                        </Link>
                      </>
                    )}

                    {token ? (
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown left-0">
                        {AccountDropDown}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="main-categori-wrap d-none d-lg-block custom_location w-100"
                  style={{ marginRight: 0 }}
                >
                  <a
                    href="#!"
                    onClick={handleLocationPopup}
                    className="categories-button-active "
                  >
                    {/* <img src={locationIcon} alt="hotline" /> */}
                    <div className="me-1">
                      <MdLocationOn size={"20px"} />
                    </div>
                    <div>
                      <p className="m-0 p-0 text-size-14 text-muted line-h-16px">
                        Delivery location
                      </p>
                      {userPlace?.streetName && (
                        <p className="m-0 p-0 text-size-14 text-white line-h-16px">
                          {userPlace?.streetName
                            ? userPlace?.streetName?.slice(
                                0,
                                width <= 1200 ? 10 : 30
                              )
                            : "Pic Delivery Location"}
                        </p>
                      )}
                    </div>
                  </a>
                </div>
              </div>

              <div className="logo logo-width-2 width-fixed">
                <Link to="/">
                  <LazyLoad height={80}>
                    <img
                      src={
                        company?.logo[0]?.url
                          ? useCloudinary(company?.logo[0]?.url, "w_300")
                          : MainLogo
                      }
                      height="80px"
                      width={"100%"}
                      alt="chocochi-logo"
                      className="fit-contain"
                      title="chocochi-logo"
                      loading="eager"
                    />
                  </LazyLoad>
                </Link>
              </div>

              <div className="header-right me-3 width-fixed justify-content-end">
                {width >= 998 && (
                  <div className="home-search-wrapper">
                    <div className="search-style-2 position-relative ">
                      <Search isMinimal={true} />
                    </div>
                  </div>
                )}

                <div className="header-action-right">
                  <div className="header-action-2" style={{ width: "30px" }}>
                    <div className="header-action-icon-2">
                      <Link to={"#!"} onClick={() => handleLoginFun("/cart")}>
                        <div className="mini-cart-icon d-flex align-items-center justify-content-center">
                          <img
                            alt="chocochi-cart"
                            title="chocochi-cart"
                            loading="eager"
                            src={iconCart}
                          />

                          {handleCartItem()?.length >= 1 ? (
                            <span className="pro-count blue">
                              {handleCartItem()?.length}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </Link>

                      {CartIconAndDropDown}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* // header bottom bar start*/}
        {!pathsThatNotShowInCheckout?.includes(location.pathname) && (
          <>
            <div className="header-nav d-none d-lg-flex py-2 w-100">
              <div className="main-menu main-menu-padding-1 w-100 main-menu-lh-2 d-none d-lg-block  font-heading">
                <nav>
                  <ul className="d-flex align-items-center justify-content-center fontColorGoldenList">
                    <li>
                      <Link to={"/about"}>About</Link>
                    </li>
                    {allCategories?.slice(0, 4)?.map((cat, key) => (
                      <li key={key}>
                        <Link
                          to={"#"}
                          onClick={e =>
                            selectCategory(e, cat?.primaryLang?.name)
                          }
                        >
                          {" "}
                          {cat?.primaryLang?.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </>
        )}
        {/* // header bottom bar end */}

        {/* // mobile */}
        <div
          className={
            scroll && productPaths?.includes(location.pathname)
              ? "header-bottom sticky-bar stick"
              : "header-bottom sticky-bar"
          }
        >
          {width <= 480 && (
            <div className="container">
              <div className="header-wrap header-space-between position-relative ">
                <div
                  className="logo logo-width d-block d-lg-none d-flex align-items-center"
                  style={{ padding: width > 768 && "15px 0", width: "60px" }}
                >
                  <div className="header-action-icon d-lg-none d-flex align-items-center">
                    <div
                      className="burger-icon burger-icon-white"
                      onClick={toggleClick}
                    >
                      <span className="burger-icon-top"></span>
                      <span className="burger-icon-mid"></span>
                      <span className="burger-icon-bottom"></span>
                    </div>

                    {/* <Link
                      to="#!"
                      onClick={handleLocationPopup}
                      className="categories-button-active ms-2 "
                    >
                      <div className="me-1 d-flex align-items-center">
                        <MdLocationOn size={"20px"} color="white" />
                        {userPlace?.streetName && (
                          <p className="m-0 p-0 text-size-14 text-white line-h-16px">
                            {userPlace?.streetName?.slice(0, 5)}...
                          </p>
                        )}
                      </div>
                    </Link> */}
                  </div>
                </div>

                <div
                  className="logo logo-width d-lg-none d-flex align-items-center"
                  style={{ padding: width > 768 && "15px 0" }}
                >
                  <Link
                    to="/"
                    className="mx-1"
                    style={{ height: "50px", width: "150px" }}
                  >
                    <LazyLoad height={200}>
                      <img
                        src={
                          company?.logo[0]?.url
                            ? useCloudinary(company?.logo[0]?.url, "q_auto")
                            : MainLogo
                        }
                        alt="chocochi-logo"
                        height="100%"
                        width={"100%"}
                        className="fit-cover"
                        title="chocochi-logo"
                        loading="eager"
                      />
                    </LazyLoad>
                  </Link>
                </div>

                <div
                  className="header-action-right d-block d-lg-none"
                  style={{ width: "70px" }}
                >
                  <div className="header-action-2">
                    {!token ? (
                      <div className="header-action-icon-2">
                        <Link
                          className="m-0 d-flex align-items-center justify-content-center"
                          to="#!"
                          onClick={handleLoginPopup}
                        >
                          <span>
                            <img
                              title="chocochi-user"
                              loading="eager"
                              className="svgInject"
                              alt="chocochi-user"
                              style={{
                                width: "18px",
                                height: "18px",
                              }}
                              src={iconUser}
                            />
                          </span>
                        </Link>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="header-action-icon-2">
                      <Link
                        to={"#!"}
                        onClick={() => handleLoginFun("/cart")}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <span className="mini-cart-icon">
                          <img
                            alt="chocochi-cart"
                            title="chocochi-cart"
                            loading="eager"
                            src={iconCart}
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                          />
                          {handleCartItem()?.length >= 1 && (
                            <span
                              className="pro-count text-white navIcon2 bg-darkGreen px-0"
                              style={{ borderRadius: "50%" }}
                            >
                              {handleCartItem()?.length}
                            </span>
                          )}
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* // mobile search bar */}
              <div className="d-flex align-items-center w-100">
                <Link
                  to="#!"
                  onClick={handleLocationPopup}
                  className="categories-button-active me-2  location-bar w-75"
                >
                  <div
                    className=" d-flex align-items-center"
                    style={{
                      borderRadius: "20px",
                      backgroundColor: "#35554c",
                      padding: "6px",
                    }}
                  >
                    <MdLocationOn
                      size={"20px"}
                      color="#d1c3ab"
                      className="me-1"
                    />
                    {userPlace?.streetName ? (
                      <p className="m-0 p-0 text-size-14 text-white line-h-16px">
                        {userPlace?.streetName?.slice(0, 30)}
                        {userPlace?.streetName?.length > 30 && "..."}
                      </p>
                    ) : (
                      <p className="m-0 p-0 text-size-14 text-white line-h-16px">
                        select delivery location
                      </p>
                    )}
                  </div>
                </Link>
                <div className="home-search-wrapper m-auto mb-2 mt-2 w-25">
                  <div className="search-style-2">
                    <Search isMobile={true} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </header>

      {/* // location button */}
      {/* <div
        className="main-categori-wrap mt-4 mb-2 m-auto p-1 display-sm-block mx-1 custom_location rounded-md"
        style={{ marginRight: 0 }}
      >
        <a
          href="#!"
          onClick={handleLocationPopup}
          className="categories-button-active rounded "
        >
          <img src={locationIcon} alt="hotline" />
          <div>
            <p
              className="m-0 p-0 text-size-14 line-h-16px"
              style={{
                color: "#d0c3ab",
              }}
            >
              Delivery location
            </p>
            {userPlace?.streetName && (
              <p className="m-0 p-0 text-size-14 text-white line-h-16px">
                {userPlace?.streetName
                  ? userPlace?.streetName
                  : "Pic Delivery Location"}
              </p>
            )}
          </div>
        </a>
      </div> */}
    </>
  )
}

export default Header

Header.propTypes = {
  totalCartItems: PropTypes.any,
  toggleClick: PropTypes.any,
  totalWishlistItems: PropTypes.any,
  headerStyle: PropTypes.string,
}
