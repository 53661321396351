import { map } from "lodash"
import React, { useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import {
  getUserLocation,
  getUserPlaces,
  hideAreasListAction,
} from "store/actions"

const AreaListPopup = () => {
  const dispatch = useDispatch()

  const { isAreaList, nearestAvailableStores } = useSelector(state => ({
    isAreaList: state.Layout.isAreaList,
    nearestAvailableStores: state.NearbyStore.store.nearestAvailableStores,
  }))

  const handleClose = () => {
    dispatch(hideAreasListAction())
  }

  const [selectedLocation, setSelectedLocation] = useState()

  const handleCheckBox = (e, id, dist) => {
    const lat = dist?.location?.coordinates[0]
    const lng = dist?.location?.coordinates[1]
    if (e.target.checked === true) {
      setSelectedLocation(id)
      dispatch(getUserLocation(dist?.location?.coordinates))
      localStorage.setItem("pre_location", [e.latLng?.lat(), e.latLng?.lng()])
      dispatch(getUserPlaces(lat, lng))
    }
    dispatch(hideAreasListAction())
  }

  return (
    <>
      <div
        className={
          !isAreaList
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal  show d-block"
        }
      >
        <div className="modal-dialog">
          <div
            className="modal-content px-0 p-0 w-fit m-auto"
            style={{ minWidth: "50%" }}
          >
            <button
              type="button"
              className="btn-close"
              onClick={handleClose}
            ></button>
            <div className="modal-body p-1 sm:p-4">
              <div
                className="deal custom-border align-items-center flex-col d-flex justify-content-center sm:p-0 p-1"
                style={{ minHeight: "fit-content" }}
              >
                <div
                  className="deal-top d-flex justify-between align-items-center h-50 pb-0 "
                  style={{
                    padding: "6px 20px 18px",
                  }}
                >
                  <div>
                    <h5 className="mb-3">Available Areas </h5>
                  </div>
                </div>

                {nearestAvailableStores ? (
                  <>
                    <div className="deal-content pt-4 px-4 sm:pt-4 pb-10">
                      <ul>
                        {map(nearestAvailableStores, (store, key) => (
                          <li
                            key={key}
                            onClick={e =>
                              handleCheckBox(e, store?._id, store?.dist)
                            }
                            className="d-flex align-items-start"
                          >
                            <div style={{ height: "22px" }}>
                              <input
                                type="checkbox"
                                className="form-check-input cursor-pointer"
                                name={store?.storeName}
                                value={store?.storeName}
                                checked={selectedLocation === store?._id}
                                id={store?._id}
                                onChange={e =>
                                  handleCheckBox(e, store?._id, store?.dist)
                                }
                              />
                            </div>
                            <label
                              className="mx-1 cursor-pointer"
                              htmlFor={store?._id}
                              style={{ fontSize: "16px" }}
                            >
                              {store?.storeName}
                            </label>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </>
                ) : (
                  <p>No Stores Available</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !isAreaList
            ? "modal-backdrop fade d-none"
            : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default AreaListPopup
