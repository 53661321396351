import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import ProductCardOne from "components/ecommerce/productCards/ProductCardOne"
import PropTypes from "prop-types"
import { useWindowSize } from "react-use"
import MeltingButton from "components/Common/Buttons/MeltingButton"
import { Col, Row } from "reactstrap"
import { map } from "lodash"
import { useHistory } from "react-router-dom"

SwiperCore.use([Navigation])

function ProductVariant({ data }) {
  const history = useHistory()
  const { width } = useWindowSize()

  const gridSize = data?.gridSize
  const relatedProd = data?.productVariants

  const handleWindowSizeVsSliderPreview = () => {
    if (width < 480) {
      return 2
    } else if (width < 640) {
      return 2.5
    } else if (width < 768) {
      return 3.5
    } else return 5.5
  }

  return (
    <>
      {data?.productVariantLayout === "carousel" ? (
        <div className="row p-4 product-varient related-product-wrap">
          <div className="col-12">
            {data?.title && (
              <h2
                className="section-head"
                style={{ textAlign: data?.textAlign }}
              >
                {data?.title}
              </h2>
            )}
            {data?.description && (
              <p className="section-description">{data?.description}</p>
            )}
            <div className="row related-products position-relative pages-product-varient mt-30">
              <>
                <Swiper
                  initialSlide={1}
                  centeredSlides={false}
                  freeMode={true}
                  loop={false}
                  spaceBetween={20}
                  slidesPerView={handleWindowSizeVsSliderPreview()}
                  grid={{
                    rows: 2,
                  }}
                  keyboard={{ enabled: true }}
                  direction="horizontal"
                  navigation={{
                    prevEl: ".custom_prev_n",
                    nextEl: ".custom_next_n",
                  }}
                  autoplay={false}
                  speed={800}
                  className=""
                  breakpoints={{
                    480: {
                      slidesPerView: 1.5,
                    },
                    640: {
                      slidesPerView: 2.5,
                    },
                    768: {
                      slidesPerView: 3.5,
                    },
                    1024: {
                      slidesPerView: 5.5,
                    },
                  }}
                >
                  {relatedProd?.map((product, i) => (
                    <SwiperSlide key={i}>
                      <ProductCardOne product={product} />
                    </SwiperSlide>
                  ))}
                </Swiper>

                <div className="slider-arrow slider-arrow-2 carausel-6-columns-arrow">
                  <span className="slider-btn slider-prev slick-arrow custom_prev_n">
                    <i className="fi-rs-angle-left"></i>
                  </span>
                  <span className="slider-btn slider-next slick-arrow custom_next_n">
                    <i className="fi-rs-angle-right"></i>
                  </span>
                </div>
              </>
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <MeltingButton link={"/products"} text={"VIEW ALL PRODUCTS"} />
            </div>
          </div>
        </div>
      ) : (
        <Row className="mt-30 px-4 justify-content-center">
          {map(data?.productVariants, (product, key) => (
            <Col
              className="p-0 px-1"
              lg={12 / gridSize?.lg}
              xs={12 / gridSize?.sm}
              key={key}
              onClick={() => (data?.link ? history.push(`${data?.link}`) : "")}
              style={{ cursor: data?.link ? "pointer" : "default" }}
            >
              <div key="d" className="gridItem">
                <ProductCardOne product={product} />
              </div>
            </Col>
          ))}
        </Row>
      )}
    </>
  )
}

export default ProductVariant

ProductVariant.propTypes = {
  data: PropTypes.any,
}
