import React from "react"
import { FooterLogo } from "assets/imgs/logos"
import { Link, useLocation } from "react-router-dom"
import { Col, Row } from "reactstrap"
import { AiOutlineInstagram } from "react-icons/ai"
import { FaLinkedinIn, FaTiktok, FaWhatsapp } from "react-icons/fa"
import { RiSnapchatLine, RiWhatsappFill } from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux"
import LazyLoad from "react-lazyload"
import { TOKEN_PREFIX } from "helpers/api_methods"
import { showLoginPopupAction } from "store/actions"
import useLangSwitch from "components/hooks/useLanguageSwicth"
import { MdOutlinePhone } from "react-icons/md"
import { FiMail } from "react-icons/fi"
import { BsFacebook } from "react-icons/bs"
import { useWindowSize } from "react-use"

function Footer() {
  const location = useLocation()
  const dispatch = useDispatch()
  const { width } = useWindowSize()

  const { socialLinks, company, loadingProd, specialMessages } = useSelector(
    state => ({
      company: state?.SiteSettings.siteSettings?.company,
      socialLinks: state.SiteSettings.siteSettings.socialLinks,
      loadingProd: state.Products.loading,
      specialMessages: state.SiteSettings.siteSettings.specialMessages,
    })
  )

  const token = localStorage.getItem(TOKEN_PREFIX)

  const language = useLangSwitch(
    specialMessages?.primaryLang,
    specialMessages?.secondaryLang
  )

  const handleLoginPopup = () => dispatch(showLoginPopupAction())

  return (
    <>
      <div className="footer">
        <Row className="m-0">
          <Col lg={8} className="sm:p-0">
            <div className="d-flex align-items-center logos">
              <Link to={"/"}>
                <LazyLoad height={156}>
                  <img
                    src={FooterLogo}
                    alt="footer-logo"
                    className="logo-footer"
                    loading="lazy"
                    width={220}
                    height={100}
                  />
                </LazyLoad>
              </Link>
              <div className="footerAddress sm:m-0">
                <p>{company?.address?.addressLine1}</p>
                <p> {company?.address?.addressLine2}</p>
                <p>
                  {company?.address?.district}&nbsp;{company?.address?.state}
                  &nbsp;{company?.address?.country}&nbsp;
                  {company?.address?.pinCode}
                </p>
                <div className="d-flex align-items-center flex-wrap justify-content-start sm:justify-content-center mt-3 mb-30 ">
                  <p
                    className="mb-0 me-2"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    <MdOutlinePhone
                      style={{
                        color: "#801424",
                      }}
                      className="me-2 "
                    />
                    {company?.contactDetails?.mobile}
                  </p>
                  <p
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    <FiMail
                      style={{
                        color: "#801424",
                      }}
                      className="me-2 m-0 p-0"
                    />
                    {company?.contactDetails?.email}
                  </p>
                </div>
                <div className="mobile-social-icon d-flex align-items-center  ">
                  <p
                    className="mb-0 me-2"
                    style={{
                      color: "#3C6056",
                    }}
                  >{`Let's be friends?`}</p>
                  {socialLinks?.facebook && (
                    <a
                      href={socialLinks?.facebook}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <BsFacebook
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                        size={"18px"}
                      />
                    </a>
                  )}
                  {socialLinks?.instagram && (
                    <a
                      href={socialLinks?.instagram}
                      target="_blank"
                      className="d-flex align-items-center justify-content-center"
                      rel="noopener noreferrer"
                    >
                      <AiOutlineInstagram
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                        size={"20px"}
                      />
                    </a>
                  )}

                  {socialLinks?.linkedin && (
                    <a
                      href={socialLinks?.linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaLinkedinIn color="white" />
                    </a>
                  )}

                  {socialLinks?.whatsApp && (
                    <a
                      href={socialLinks?.whatsApp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <RiWhatsappFill
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                        size={"20px"}
                      />
                    </a>
                  )}
                  {socialLinks?.snapchat && (
                    <a
                      href={socialLinks?.snapchat}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <RiSnapchatLine
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                      />
                    </a>
                  )}
                  {socialLinks?.tiktok && (
                    <a
                      href={socialLinks?.tiktok}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <FaTiktok
                        style={{
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                      />
                    </a>
                  )}
                </div>
                {width <= 768 && (
                  <>
                    <div className="d-flex align-items-center justify-content-center mt-30">
                      <Link
                        className="mobile-links"
                        to="/privacy-policy"
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Privacy Policy
                      </Link>
                      <Link
                        className="mobile-links"
                        to="/terms"
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Terms & Conditions
                      </Link>
                      <Link
                        className="mobile-links"
                        to="/return-policy"
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Return Policy
                      </Link>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mt-3">
                      <Link
                        className="mobile-links"
                        to="/contact"
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Contact Us
                      </Link>

                      {/* <Link
                        className="mobile-links"
                        to="/wishlist"
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Wishlist
                      </Link> */}
                      <Link
                        className="mobile-links"
                        to={{
                          pathname: "/user-account",
                          state: { index: 2 },
                        }}
                        style={{
                          color: "rgba(0, 0, 0, 0.35)",
                        }}
                      >
                        Order Tracking
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </Col>
          <Col lg={4} className="p-0">
            {width >= 768 && (
              <div className="d-flex">
                <div
                  className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h3 className="widget-title h5">Company</h3>
                  <ul className="footer-list  mb-sm-5 mb-md-0">
                    <li>
                      <Link to="/privacy-policy">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="/terms">Terms</Link>
                    </li>
                    <li>
                      <Link to="/return-policy">Return Policy</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                  </ul>
                </div>
                <div
                  className="footer-link-widget col col-xs-6 wow animate__animated animate__fadeInUp"
                  data-wow-delay=".1s"
                >
                  <h3 className="widget-title h5">Account</h3>
                  <ul className="footer-list  mb-sm-5 mb-md-0">
                    <li>
                      {token ? (
                        <Link to="/user-account">Account</Link>
                      ) : (
                        <Link to="#" onClick={handleLoginPopup}>
                          Login
                        </Link>
                      )}
                    </li>
                    <li>
                      <Link to="/cart">View Cart</Link>
                    </li>
                    {/* <li>
                      <Link to="/wishlist">My Wishlist</Link>
                    </li> */}
                    <li>
                      <Link
                        to={{
                          pathname: "/user-account",
                          state: { index: 2 },
                        }}
                      >
                        My Order
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>

      <div className="header-top header-top-ptb-1 d-none d-lg-block">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-8">
              <div className="header-info">
                <ul>
                  <li>
                    {token ? (
                      <Link
                        to={{
                          pathname: "/user-account",
                          state: { index: 4 },
                        }}
                      >
                        <span>My Account</span>
                      </Link>
                    ) : (
                      <span
                        onClick={handleLoginPopup}
                        className="cursor-pointer"
                      >
                        <i className="fi fi-rs-user mr-10"></i>
                        Login
                      </span>
                    )}
                  </li>
                  {/* <li>
                    <Link to={"#!"} onClick={() => handleLoginFun("/wishlist")}>
                      <span>Wish List</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to={{
                        pathname: "/user-account",
                        state: { index: 2 },
                      }}
                    >
                      <span>Order Tracking</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={{
                        pathname: "/products",
                        query: {
                          prod: "all",
                        },
                      }}
                    >
                      <span>All Products</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/categroies"}>
                      <span>Categories</span>
                    </Link>
                  </li>
                  <li>
                    <Link to={"/collections"}>
                      <span>Collections</span>
                    </Link>
                  </li>
                  {/* <li>
                    <Link to={"/pages"}>
                      <span>Pages</span>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to={"/blogs"}>
                      <span>Blogs</span>
                    </Link>
                  </li> */}
                  <li>
                    <Link to={"/about"}>
                      <span>About</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            {/* <div className="col-xl-6 col-lg-4">
              <div className="text-center">
                <div id="news-flash" className="d-inline-block">
                  <ul>
                    {language?.bannerOffer && (
                      <li>
                        {language?.bannerOffer}&nbsp;
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-xl-6 col-lg-4">
              <div className="header-info header-info-right">
                <ul>
                  <li>
                    Need help ?&nbsp; Call Us&nbsp;:{" "}
                    <strong className="text-brand">
                      &nbsp;{company?.contactDetails?.mobile}
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer
