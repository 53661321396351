/* SITE_SETTINGS */
export const GET_SITE_SETTINGS = "GET_SITE_SETTINGS"
export const GET_SITE_SETTINGS_SUCCESS = "GET_SITE_SETTINGS_SUCCESS"
export const GET_SITE_SETTINGS_FAIL = "GET_SITE_SETTINGS_FAIL"

export const GET_PAYMENT_KEY = "GET_PAYMENT_KEY"
export const GET_PAYMENT_KEY_SUCCESS = "GET_PAYMENT_KEY_SUCCESS"
export const GET_PAYMENT_KEY_FAIL = "GET_PAYMENT_KEY_KEY"

export const GET_DEFAULT_STORE = "GET_DEFAULT_STORE"
export const GET_DEFAULT_STORE_SUCCESS = "GET_DEFAULT_STORE_SUCCESS"
export const GET_DEFAULT_STORE_FAIL = "GET_DEFAULT_STORE_KEY"
