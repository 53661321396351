import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_GMAP_PKEY,
  GET_NEAREST_STORE,
  USE_REVERSE_GEOCODE,
  SEARCH_LOCATION,
  GET_NEAREST_STORE_NO_USER,
} from "./actionTypes"
import {
  getNearestStoreSuccess,
  getNearestStoreFail,
  getMapPublicKeySuccess,
  getMapPublicKeyFail,
  searchPlacesSuccess,
  searchPlacesFail,
  getUserPlacesSuccess,
  getUserPlacesFail,
  getNearestStoreNoUserSuccess,
  getNearestStoreNoUserFail,
} from "./actions"

import { get, post } from "helpers/api_methods"

//near store
function getNearestStoreAPi(location) {
  return post(`/store/user/near`, location)
}
function getNearestStoreWithOutUserAPi(location) {
  return post(`/store/near`, location)
}

function* fetchNearestStore({ payload }) {
  try {
    const response = yield call(getNearestStoreAPi, payload)
    yield put(getNearestStoreSuccess(response))
  } catch (error) {
    yield put(getNearestStoreFail(error))
  }
}

function* fetchNearestStoreWithOutUser({ payload }) {
  try {
    const response = yield call(getNearestStoreWithOutUserAPi, payload)
    yield put(getNearestStoreNoUserSuccess(response))
  } catch (error) {
    yield put(getNearestStoreNoUserFail(error))
  }
}

//location and map
function getGmapKeyAPi() {
  return get("/location/googlemap-api-key")
}

function getUserPlaceAPi({ lat, lng }) {
  return get(`/location/reverse-geocode/${lat}/${lng}`)
}

function* fetchGmapKey() {
  try {
    const response = yield call(getGmapKeyAPi)
    yield put(getMapPublicKeySuccess(response))
  } catch (error) {
    yield put(getMapPublicKeyFail(error))
  }
}
//search location
function getSearchLocationAPi(query) {
  // if (query) {
  return get(`/location/search/${query}`)
  // }
}

function* searchPlaces({ payload }) {
  try {
    const response = yield call(getSearchLocationAPi, payload)
    yield put(searchPlacesSuccess(response))
  } catch (error) {
    yield put(searchPlacesFail(error))
  }
}

function* fetchUserPlace({ payload }) {
  try {
    const response = yield call(getUserPlaceAPi, payload)
    yield put(getUserPlacesSuccess(response))
  } catch (error) {
    yield put(getUserPlacesFail(error))
  }
}

function* storeSaga() {
  yield takeEvery(GET_NEAREST_STORE, fetchNearestStore)
  yield takeEvery(GET_GMAP_PKEY, fetchGmapKey)
  yield takeEvery(SEARCH_LOCATION, searchPlaces)
  yield takeEvery(USE_REVERSE_GEOCODE, fetchUserPlace)
  yield takeEvery(GET_NEAREST_STORE_NO_USER, fetchNearestStoreWithOutUser)
}

export default storeSaga
