import {
  GET_DEFAULT_STORE_FAIL,
  GET_DEFAULT_STORE_SUCCESS,
  GET_DEFAULT_STORE,
  GET_PAYMENT_KEY,
  GET_PAYMENT_KEY_FAIL,
  GET_PAYMENT_KEY_SUCCESS,
  GET_SITE_SETTINGS,
  GET_SITE_SETTINGS_FAIL,
  GET_SITE_SETTINGS_SUCCESS,
} from "./actionTypes"

const INIT_STATE = {
  siteSettings: {},
  error: {},
  loading: false,
  paymentKey: {},
  paymentError: {},
  defaultStore: "",
}

const SiteSettings = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SITE_SETTINGS:
    case GET_PAYMENT_KEY:
    case GET_DEFAULT_STORE:
      return {
        ...state,
        loading: true,
      }

    case GET_SITE_SETTINGS_SUCCESS:
      return {
        ...state,
        siteSettings: action.payload,
        loading: false,
      }

    case GET_SITE_SETTINGS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_PAYMENT_KEY_SUCCESS:
      return {
        ...state,
        paymentKey: action.payload,
        loading: false,
      }

    case GET_PAYMENT_KEY_FAIL:
      return {
        ...state,
        paymentError: action.payload,
        loading: false,
      }

    case GET_DEFAULT_STORE_SUCCESS:
      return {
        ...state,
        defaultStore: action.payload,
        loading: false,
      }

    case GET_DEFAULT_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    default:
      return state
  }
}

export default SiteSettings
