import React from "react"
import PropTypes from "prop-types"
import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import useWindow from "components/hooks/useWindow"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"
import { Col, Row } from "reactstrap"
function ShortContent({ data }) {
  const { isMobile } = useWindow()
  const history = useHistory()

  const shortContentData = data?.shortContents
  const gridSize = data?.gridSize

  const web = (
    <div
      className="shortContent"
      style={{
        minHeight: 200,
        backgroundColor: data?.bgColor ? data?.bgColor : "#007551",
        cursor: data?.link ? "pointer" : "default",
      }}
      onClick={() => (data?.link ? history.push(`${data?.link}`) : "")}
    >
      <Row className="d-flex align-items-center justify-content-center px-1 shortContentInner row w-100">
        {data?.title && (
          <h2
            className="section-head mb-2"
            style={{ textAlign: data?.textAlign }}
          >
            {data?.title}
          </h2>
        )}
        {data?.description && (
          <p className="section-description mb-4">{data?.description}</p>
        )}
        {map(shortContentData, (short, key) => (
          <Col
            lg={12 / gridSize?.lg}
            xs={12 / gridSize?.sm}
            className="d-flex-center-center flex-column content"
            key={key}
          >
            <LazyLoad height={200}>
              <img
                src={useCloudinary(
                  short?.image?.url ?? "",
                  isMobile ? "w_300" : "w_300,q_60"
                )}
                className=""
                width={100}
                height={100}
                alt="short-content"
                loading="lazy"
              />
            </LazyLoad>

            <h4 style={{ color: data?.fgColor ? data?.fgColor : "#ffffff" }}>
              {short?.title}
            </h4>
          </Col>
        ))}
      </Row>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default ShortContent

ShortContent.propTypes = {
  data: PropTypes.any,
}
