import {
  GET_GMAP_PKEY,
  GET_GMAP_PKEY_FAIL,
  GET_GMAP_PKEY_SUCCESS,
  GET_NEAREST_STORE,
  GET_NEAREST_STORE_FAIL,
  GET_NEAREST_STORE_SUCCESS,
  GET_USER_LOCATION,
  SEARCH_LOCATION,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION_FAIL,
  USE_REVERSE_GEOCODE,
  USE_REVERSE_GEOCODE_SUCCESS,
  USE_REVERSE_GEOCODE_FAIL,
  GET_NEAREST_STORE_NO_USER,
  GET_NEAREST_STORE_NO_USER_SUCCESS,
  GET_NEAREST_STORE_NO_USER_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  store: [],
  error: {},
  loading: false,
  location: [],
  map: "",
  mapLoading: false,
  places: {
    places: [],
  },
  placesLoading: false,
  userPlace: "",
  userPlaceLoad: "",
}

const StoreItems = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_LOCATION:
      return {
        ...state,
        location: action.payload,
      }

    case GET_NEAREST_STORE:
    case GET_NEAREST_STORE_NO_USER:
      return {
        ...state,
        loading: true,
      }

    case GET_NEAREST_STORE_SUCCESS:
      return {
        ...state,
        store: action.payload,
        loading: false,
      }
    case GET_NEAREST_STORE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_NEAREST_STORE_NO_USER_SUCCESS:
      return {
        ...state,
        store: action.payload,
        loading: false,
      }
    case GET_NEAREST_STORE_NO_USER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_GMAP_PKEY:
      return {
        ...state,
        mapLoading: true,
      }

    case GET_GMAP_PKEY_SUCCESS:
      return {
        ...state,
        map: action.payload,
        mapLoading: false,
      }

    case GET_GMAP_PKEY_FAIL:
      return {
        ...state,
        error: action.payload,
        mapLoading: false,
      }

    case SEARCH_LOCATION:
      return {
        ...state,
        placesLoading: true,
      }

    case SEARCH_LOCATION_SUCCESS:
      return {
        ...state,
        places: action.payload,
        placesLoading: false,
      }

    case SEARCH_LOCATION_FAIL:
      return {
        ...state,
        error: action.payload,
        placesLoading: false,
      }

    case USE_REVERSE_GEOCODE:
      return {
        ...state,
        userPlaceLoad: true,
      }

    case USE_REVERSE_GEOCODE_SUCCESS:
      return {
        ...state,
        userPlace: action.payload,
        userPlaceLoad: false,
      }

    case USE_REVERSE_GEOCODE_FAIL:
      return {
        ...state,
        error: action.payload,
        userPlaceLoad: false,
      }

    default:
      return state
  }
}

export default StoreItems
