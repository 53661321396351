import React, { useState } from "react"
import classnames from "classnames"
import { Collapse } from "reactstrap"
import PropTypes from "prop-types"
import { map } from "lodash"
import { useCustomDevice } from "components/hooks/useCustomDevice"

function FaqData({ data }) {
  const [tab, setTab] = useState("")

  console.log(data)

  const web = (
    <div className="container">
      <div className="px-2">
        <div className={"testimonials"}>
          <div className="mt-4 p-5 sm:p-0">
            <h4>Frequently Asked Question</h4>
            <div
              className="accordion accordion-flush"
              id="accordionFlushExample"
            >
              {map(data?.faqData, (item, key) => (
                <div className="accordion-item" key={key}>
                  <h2 className="accordion-header" id="headingFlushOne">
                    <button
                      className={classnames("accordion-button", "fw-medium", {
                        collapsed: tab !== key + 1,
                      })}
                      type="button"
                      onClick={() => setTab(key + 1)}
                      style={{ cursor: "pointer" }}
                    >
                      {item?.question}
                    </button>
                  </h2>

                  <Collapse
                    isOpen={tab === key + 1}
                    className="accordion-collapse"
                  >
                    <div className="accordion-body">
                      <div className="" style={{ color: "black" }}>
                        {item?.answer}
                      </div>
                    </div>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default FaqData
FaqData.propTypes = {
  data: PropTypes.any,
}
