// @flow
import {
  CHANGE_LAYOUT,
  CHANGE_LAYOUT_WIDTH,
  CHANGE_SIDEBAR_THEME,
  CHANGE_SIDEBAR_TYPE,
  CHANGE_TOPBAR_THEME,
  SHOW_RIGHT_SIDEBAR,
  SHOW_LOGIN_POPUP,
  CHANGE_SIDEBAR_THEME_IMAGE,
  CHANGE_PRELOADER,
  TOGGLE_LEFTMENU,
  SHOW_SIDEBAR,
  HIDE_LOGIN_POPUP,
  SHOW_TOASTER,
  HIDE_TOASTER,
  SHOW_LOCATION_POPUP,
  HIDE_LOCATION_POPUP,
  SET_SITE_LANGUAGE,
  SHOW_CART_NOTIFICATION,
  HIDE_CART_NOTIFICATION,
  HIDE_AREAS_LIST,
  SHOW_AREAS_LIST,
  HIDE_MOBILE_NAV,
  SHOW_MOBILE_NAV,
} from "./actionTypes"

//constants
import {
  layoutTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
} from "../../constants/layout"

const INIT_STATE = {
  layoutType: layoutTypes.VERTICAL,
  layoutWidth: layoutWidthTypes.FLUID,
  leftSideBarTheme: leftSideBarThemeTypes.LIGHT,
  leftSideBarThemeImage: leftBarThemeImageTypes.NONE,
  leftSideBarType: leftSidebarTypes.DEFAULT,
  topbarTheme: topBarThemeTypes.LIGHT,
  isPreloader: false,
  showRightSidebar: false,
  isMobile: false,
  showSidebar: true,
  leftMenu: false,
  isLoginPopup: false,
  notification: {
    type: "",
    status: false,
    position: "",
    data: {},
  },
  isLocationPopup: false,
  siteLanguage: "en",
  cartNoti: false,
  isAreaList: false,
  mobileNav: true,
}

const Layout = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LAYOUT:
      return {
        ...state,
        layoutType: action.payload,
      }
    case CHANGE_PRELOADER:
      return {
        ...state,
        isPreloader: action.payload,
      }

    case CHANGE_LAYOUT_WIDTH:
      return {
        ...state,
        layoutWidth: action.payload,
      }
    case SET_SITE_LANGUAGE:
      return {
        ...state,
        siteLanguage: action.payload,
      }
    case CHANGE_SIDEBAR_THEME:
      return {
        ...state,
        leftSideBarTheme: action.payload,
      }
    case CHANGE_SIDEBAR_THEME_IMAGE:
      return {
        ...state,
        leftSideBarThemeImage: action.payload,
      }
    case CHANGE_SIDEBAR_TYPE:
      return {
        ...state,
        leftSideBarType: action.payload.sidebarType,
      }
    case CHANGE_TOPBAR_THEME:
      return {
        ...state,
        topbarTheme: action.payload,
      }
    case SHOW_RIGHT_SIDEBAR:
      return {
        ...state,
        showRightSidebar: action.payload,
      }
    case SHOW_LOGIN_POPUP:
      return {
        ...state,
        isLoginPopup: true,
      }
    case HIDE_LOGIN_POPUP:
      return {
        ...state,
        isLoginPopup: false,
      }

    case SHOW_LOCATION_POPUP:
      return {
        ...state,
        isLocationPopup: true,
      }
    case HIDE_LOCATION_POPUP:
      return {
        ...state,
        isLocationPopup: false,
      }

    case SHOW_TOASTER:
      return {
        ...state,
        notification: {
          status: true,
          ...action.payload,
        },
      }
    case HIDE_TOASTER:
      return {
        ...state,
        notification: {
          ...state.notification,
          status: false,
        },
      }
    case SHOW_SIDEBAR:
      return {
        ...state,
        showSidebar: action.payload,
      }
    case TOGGLE_LEFTMENU:
      return {
        ...state,
        leftMenu: action.payload,
      }

    case SHOW_CART_NOTIFICATION:
      return {
        ...state,
        cartNoti: true,
      }
    case HIDE_CART_NOTIFICATION:
      return {
        ...state,
        cartNoti: false,
      }

    case SHOW_AREAS_LIST:
      return {
        ...state,
        isAreaList: true,
      }
    case HIDE_AREAS_LIST:
      return {
        ...state,
        isAreaList: false,
      }
    case HIDE_MOBILE_NAV:
      return {
        ...state,
        mobileNav: false,
      }
    case SHOW_MOBILE_NAV:
      return {
        ...state,
        mobileNav: true,
      }
    default:
      return state
  }
}

export default Layout
