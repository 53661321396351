import React, { useEffect } from "react"
import LandingPage from "pages/LandingPages"
import { useDispatch, useSelector } from "react-redux"
import { getPagesHome } from "store/actions"
import MetaData from "util/MetaData"

function About() {
  const dispatch = useDispatch()

  const { currentStore, defaultStore, company, siteSettings } = useSelector(
    state => ({
      siteSettings: state?.SiteSettings.siteSettings,
      currentStore: state.NearbyStore.store,
      company: state?.SiteSettings.siteSettings?.company,
      defaultStore: state.SiteSettings.defaultStore,
    })
  )

  const currentStoreId = currentStore?.store?._id
    ? currentStore?.store?._id
    : defaultStore

  useEffect(() => {
    dispatch(getPagesHome(currentStoreId, "about"))
  }, [currentStoreId])

  return (
    <>
      <MetaData
        content={"About"}
        title={"About"}
        description={"Chocochi"}
        ogImage={company?.logo[0]?.url}
        canonicalLink="https://chocochi.in/"
      />
      <LandingPage isAboutPage />
    </>
  )
}

export default About
