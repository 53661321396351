import React, { useEffect, useRef, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { getMapPublicKey, hideLocationPopupAction } from "store/actions"
import MyGoogleMap from "components/Gmap/googleMap"
import { useOutsideAlerter } from "components/hooks/useOutSideClick"
import { useWindowSize } from "react-use"

const IntroPopup = () => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const locationRef = useRef(null)

  const { isLocationPopup, store, mapKey } = useSelector(state => ({
    isLocationPopup: state.Layout.isLocationPopup,
    store: state.NearbyStore.store,
    mapKey: state.NearbyStore.map,
  }))

  const nearbyStore = store?.store

  const [outSide, setOutSide] = useState(false)

  useOutsideAlerter(locationRef, setOutSide)

  const handleRemove = () => {
    dispatch(hideLocationPopupAction())
  }

  useEffect(() => {
    dispatch(getMapPublicKey())
  }, [])

  const MAP_PUBLIC_KEY =
    mapKey?.key ?? "AIzaSyAU4EsCpVhv88dQYuWU9enLuWLcQ3-Npo8"

  useEffect(() => {
    if (outSide === true) {
      handleRemove()
      setOutSide(false)
    }
  }, [outSide])

  return (
    <>
      <div
        className={
          !isLocationPopup
            ? "modal fade custom-modal d-none"
            : "modal fade custom-modal location-popup  show d-block"
        }
      >
        <div className={`modal-dialog ${width <= 480 && "mobile_login"}`}>
          <div className="modal-content px-0 p-0" ref={locationRef}>
            <button
              type="button"
              className="btn-close"
              onClick={handleRemove}
            ></button>

            <div className="modal-body p-1">
              <div className="deal location-popup-body custom-border">
                <div
                  className="deal-top  d-flex justify-between align-items-center sm:flex-column"
                  style={{
                    padding: "6px 20px 18px",
                  }}
                >
                  <div className="text-start w-100">
                    <h2 className="text-brand ">Pick your delivery location</h2>
                    <p className="">
                      Select your delivery location to show nearby store
                    </p>
                  </div>
                  {nearbyStore?.storeName ? (
                    <div className="mr-2 locations_text">
                      <h6>Nearby store</h6>
                      <p>
                        {nearbyStore?.storeName}
                        {nearbyStore?.storeAddress?.district && ","}&nbsp;
                        {nearbyStore?.storeAddress?.district}
                        {nearbyStore?.storeAddress?.area && ","}&nbsp;
                        {nearbyStore?.storeAddress?.area}
                      </p>
                    </div>
                  ) : (
                    <div className="mr-2">
                      <h6>{store?.message}</h6>
                    </div>
                  )}
                </div>

                <div className="deal-content  detail-info">
                  {MAP_PUBLIC_KEY && (
                    <MyGoogleMap MAP_PUBLIC_KEY={MAP_PUBLIC_KEY} />
                  )}
                </div>
                <button
                  type="button"
                  className="btn btn-heading save-location-btn btn-block bg-lightgreen hover-up position-absolute bottom-40  right-80"
                  onClick={handleRemove}
                >
                  Set Location
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          !isLocationPopup
            ? "modal-backdrop fade d-none"
            : "modal-backdrop fade show"
        }
      ></div>
    </>
  )
}

export default IntroPopup
