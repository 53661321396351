/* STOREITEMS */
export const GET_NEAREST_STORE = "GET_NEAREST_STORE"
export const GET_NEAREST_STORE_SUCCESS = "GET_NEAREST_STORE_SUCCESS"
export const GET_NEAREST_STORE_FAIL = "GET_NEAREST_STORE_FAIL"

export const GET_NEAREST_STORE_NO_USER = "GET_NEAREST_STORE_NO_USER"
export const GET_NEAREST_STORE_NO_USER_SUCCESS =
  "GET_NEAREST_STORE_NO_USER_SUCCESS"
export const GET_NEAREST_STORE_NO_USER_FAIL = "GET_NEAREST_STORE_NO_USER_FAIL"

export const GET_USER_LOCATION = "GET_USER_LOCATION"

export const GET_GMAP_PKEY = "GET_GMAP_PKEY"
export const GET_GMAP_PKEY_SUCCESS = "GET_GMAP_PKEY_SUCCESS"
export const GET_GMAP_PKEY_FAIL = "GET_GMAP_PKEY_FAIL"

export const USE_REVERSE_GEOCODE = "USE_REVERSE_GEOCODE"
export const USE_REVERSE_GEOCODE_SUCCESS = "USE_REVERSE_GEOCODE_SUCCESS"
export const USE_REVERSE_GEOCODE_FAIL = "USE_REVERSE_GEOCODE_FAIL"

export const SEARCH_LOCATION = "SEARCH_LOCATION"
export const SEARCH_LOCATION_SUCCESS = "SEARCH_LOCATION_SUCCESS"
export const SEARCH_LOCATION_FAIL = "SEARCH_LOCATION_FAIL"
