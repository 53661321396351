import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { useWindowSize } from "react-use"
import PropTypes from "prop-types"

function BannerLoader({ innerClass }) {
  const { width } = useWindowSize()

  return (
    <>
      <Skeleton
        containerClassName={"d-flex mt-4 container h-90"}
        count={1}
        className={"mb-3 w-100 m-auto " + `${innerClass}`}
        style={{ height: "100%", minHeight: "300px", borderRadius: "20px" }}
        duration={2}
      />
    </>
  )
}

export default BannerLoader

BannerLoader.propTypes = {
  innerClass: PropTypes.string,
}
