import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "reactstrap"
import { map } from "lodash"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import useWindow from "components/hooks/useWindow"
import { useHistory } from "react-router-dom"
import LazyLoad from "react-lazyload"

function ImageGrid({ data }) {
  const gridSize = data?.gridSize
  const { isMobile } = useWindow()
  const history = useHistory()

  const handleRedirectLink = index => {
    if (data?.link) {
      return history.push(data?.link)
    } else if (data?.imageLinks?.length >= 0) {
      return history.push(data?.imageLinks[index])
    }
    return null
  }

  const web = (
    <div className="image-grid p-0 ">
      {data?.title && (
        <h2 className="section-head" style={{ textAlign: data?.textAlign }}>
          {data?.title}
        </h2>
      )}
      {data?.description && (
        <p className="section-description">{data?.description}</p>
      )}
      <Row className="mt-30 px-4.5">
        {map(data?.images, (img, key) => (
          <Col
            className="p-0"
            lg={12 / gridSize?.lg}
            xs={12 / gridSize?.sm}
            key={key}
            onClick={() => handleRedirectLink(key)}
            style={{ cursor: "pointer" }}
          >
            <div key="d" className="gridItem">
              <LazyLoad height={200}>
                <img
                  src={useCloudinary(
                    img?.url ?? "",
                    isMobile ? "w_500" : "w_700,q_70"
                  )}
                  height={"100%"}
                  width={"100%"}
                  alt="grid-images"
                />
              </LazyLoad>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default ImageGrid

ImageGrid.propTypes = {
  data: PropTypes.any,
}
