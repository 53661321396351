import React from "react"
import { Button } from "reactstrap"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"

function MeltingButton({ text, link }) {
  const history = useHistory()

  return (
    <Button
      className="p-0 hover-none melting-button"
      onClick={() => history.push(link)}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="260"
        height="71"
        viewBox="0 0 260 71"
        fill="none"
      >
        <path
          d="M0 12.849C0 5.75271 5.7527 0 12.849 0H246.467C253.564 0 259.316 5.74569 259.316 12.842C259.316 25.5147 259.316 43.9353 259.316 50.6353C259.316 60.2776 257.053 64.1103 252.502 65.3059C251.556 65.5545 250.54 65.6138 249.623 65.2725C246.232 64.01 245.784 59.6734 242.77 58.9228C241.844 58.6923 240.803 58.6963 239.97 59.1607C236.435 61.1313 232.56 71.0392 228.946 70.5228C227.748 70.3517 224.274 70.5527 226.61 62.3462C228.946 54.1396 209.088 56.6993 197.992 56.6993C183.39 56.6993 187.419 56.6993 161.672 56.6993C140.778 56.6993 47.3292 56.6993 12.8411 56.6993C5.74475 56.6993 0 50.9466 0 43.8503V12.849Z"
          fill="url(#paint0_linear_906_132)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_906_132"
            x1="227.194"
            y1="54.7236"
            x2="84.6867"
            y2="-16.53"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#41655B" />
            <stop offset="1" stopColor="#193C32" />
          </linearGradient>
        </defs>
      </svg>
      <p className="button-text">{text && text}</p>
    </Button>
  )
}

export default MeltingButton

MeltingButton.propTypes = {
  text: PropTypes.any,
  link: PropTypes.any,
}
