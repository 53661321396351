import React from "react"
import usePrice from "components/hooks/usePrice"
import PropTypes from "prop-types"
import useCloudinary from "components/hooks/useCloudinaryImage"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"
import { FaSpinner } from "react-icons/fa"
import { useCustomDevice } from "components/hooks/useCustomDevice"
import { useCallToAction } from "components/hooks/useCallToAction"
import useWindow from "components/hooks/useWindow"
import { Markup } from "interweave"
import LazyLoad from "react-lazyload"
function Highlight({ data }) {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isMobile } = useWindow()

  const { layoutPages, homePage, siteSettings, addOrRemoveLoad } = useSelector(
    state => ({
      layoutPages: state.LayoutPages.layoutPages,
      homePage: state.LayoutPages.homePage,
      siteSettings: state?.SiteSettings.siteSettings,
      addOrRemoveLoad: state.Cart.addOrRemoveLoad,
    })
  )

  console.log(location.pathname)

  const handlePageProductData = () => {
    if (siteSettings?.setup?.isCustomHomePage && location.pathname === "/") {
      return homePage?.product
    } else return layoutPages?.product
  }

  const product = handlePageProductData()

  console.log("title =>")
  console.log("product :", product)

  const { currencyCode, oldPrice, price, stock } = usePrice(product)

  const handleCTA = type =>
    useCallToAction(product, dispatch, type, stock, history, data?.link)

  const diff = Math.abs(oldPrice - price)

  const discountPercentage =
    (oldPrice ?? 0) > price ? Math.floor((diff / oldPrice) * 100) : ""

  const handleText = type => {
    switch (type) {
      case "left":
        return "flex-start"
      case "right":
        return "flex-end"
      default:
        return type
    }
  }

  const web = (
    <div className="container pb-4" style={{ backgroundColor: data?.bgColor }}>
      <div className="mx-5 sm:mx-0">
        <div className="d-flex-center-center highlight">
          <div className="content" style={{ textAlign: data?.textAlign }}>
            <h4 style={{ color: data?.fgColor }}>{data?.title}</h4>
            {/* <p style={{ color: data?.fgColor }}>{data?.description}</p> */}
            <Markup content={data?.description} />

            <div className="product-card-bottom mt-0">
              <div className="product-price ">
                <span
                  className="font-head-new"
                  style={{ margin: "0 5px 0 5px", fontWeight: "500" }}
                >
                  {currencyCode}
                  {price}
                </span>
                <del
                  className="old-price-new m-0"
                  style={{ fontWeight: "500" }}
                >
                  {oldPrice}
                </del>
                &nbsp;
                <span className="price-per-new">{discountPercentage}% off</span>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: handleText(data?.textAlign),
              }}
            >
              {data?.callToAction?.isRequired && data?.callToAction?.text && (
                <button
                  className="btn btn-custom d-flex align-items-center justify-content-center"
                  onClick={() => handleCTA(data?.callToAction?.action)}
                >
                  {data?.callToAction?.text}{" "}
                  {addOrRemoveLoad && <FaSpinner className="mx-2 spinner" />}
                </button>
              )}
            </div>
          </div>
          {data?.images[0]?.url && (
            <div className="image-wrapper">
              <LazyLoad height={200}>
                <img
                  src={useCloudinary(
                    data?.images[0]?.url ?? "",
                    isMobile ? "w_500" : "w_700,q_70"
                  )}
                  height={"100%"}
                  width={"100%"}
                  loading="lazy"
                  alt="highlight-main-image"
                />
              </LazyLoad>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  const mobile = web

  return useCustomDevice(data?.platform, mobile, web)
}

export default Highlight

Highlight.propTypes = {
  data: PropTypes.any,
}
