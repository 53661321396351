import {
  GET_NEAREST_STORE,
  GET_NEAREST_STORE_SUCCESS,
  GET_NEAREST_STORE_FAIL,
  GET_USER_LOCATION,
  GET_GMAP_PKEY,
  GET_GMAP_PKEY_SUCCESS,
  GET_GMAP_PKEY_FAIL,
  SEARCH_LOCATION,
  SEARCH_LOCATION_SUCCESS,
  SEARCH_LOCATION_FAIL,
  USE_REVERSE_GEOCODE,
  USE_REVERSE_GEOCODE_SUCCESS,
  USE_REVERSE_GEOCODE_FAIL,
  GET_NEAREST_STORE_NO_USER,
  GET_NEAREST_STORE_NO_USER_SUCCESS,
  GET_NEAREST_STORE_NO_USER_FAIL,
} from "./actionTypes"

export const getNearestStore = location => ({
  type: GET_NEAREST_STORE,
  payload: location,
})

export const getNearestStoreSuccess = store => ({
  type: GET_NEAREST_STORE_SUCCESS,
  payload: store,
})
export const getNearestStoreFail = error => ({
  type: GET_NEAREST_STORE_FAIL,
  payload: error,
})

export const getNearestStoreNoUser = location => ({
  type: GET_NEAREST_STORE_NO_USER,
  payload: location,
})

export const getNearestStoreNoUserSuccess = store => ({
  type: GET_NEAREST_STORE_NO_USER_SUCCESS,
  payload: store,
})
export const getNearestStoreNoUserFail = error => ({
  type: GET_NEAREST_STORE_NO_USER_FAIL,
  payload: error,
})

export const getMapPublicKey = () => ({
  type: GET_GMAP_PKEY,
})

export const getMapPublicKeySuccess = pKey => ({
  type: GET_GMAP_PKEY_SUCCESS,
  payload: pKey,
})

export const getMapPublicKeyFail = error => ({
  type: GET_GMAP_PKEY_FAIL,
  payload: error,
})

export const searchPlaces = query => ({
  type: SEARCH_LOCATION,
  payload: query,
})

export const searchPlacesSuccess = places => ({
  type: SEARCH_LOCATION_SUCCESS,
  payload: places,
})

export const searchPlacesFail = error => ({
  type: SEARCH_LOCATION_FAIL,
  payload: error,
})

export const getUserLocation = location => ({
  type: GET_USER_LOCATION,
  payload: location,
})

export const getUserPlaces = (lat, lng) => ({
  type: USE_REVERSE_GEOCODE,
  payload: { lat, lng },
})

export const getUserPlacesSuccess = places => ({
  type: USE_REVERSE_GEOCODE_SUCCESS,
  payload: places,
})

export const getUserPlacesFail = error => ({
  type: USE_REVERSE_GEOCODE_FAIL,
  payload: error,
})
